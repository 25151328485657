.main_div_Innovative {
  padding-left: 20rem;
  padding-right: 20rem;
}
.image_div {
  background: url("../../assets/imgs/back_img.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 33rem;
  border-radius: 6rem;
}

.image_above {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  border-radius: 6rem;
}

/* coloumsof row */

.columnsofrowininnovative {
  display: flex !important;
  justify-content: center !important;
  color: #fff;
  width: 100%;
}

.statsofinnovative {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background-color: #087781;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "SF Pro Display" !important;
  font-size: 2.8rem;
}

.textofrwocolumninnovative {
  text-align: center;
  color: #393939;
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: 2rem;
}

/* ending columns of row */
.stats_div {
  position: absolute;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background-color: #087781;
  margin-top: 5rem;
  margin-left: 8rem;
  font-family: "SF Pro Display" !important;
  color: #fff;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 800;
}
.stats_div1 {
  position: absolute;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background-color: #087781;
  margin-top: 5rem;
  margin-left: 31rem;
  font-family: "SF Pro Display" !important;
  color: #fff;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 800;
}
.stats_div2 {
  position: absolute;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background-color: #087781;
  margin-top: 5rem;
  margin-left: 55rem;
  font-family: "SF Pro Display" !important;
  color: #fff;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 800;
}
.stats_div3 {
  position: absolute;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background-color: #087781;
  margin-top: 5rem;
  margin-left: 77rem;
  font-family: "SF Pro Display" !important;
  color: #fff;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 800;
}

.back_green_inn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.inside_of_innovative_green {
  background-color: #94cb64;
  margin-top: 8rem;
  border-radius: 2rem;
  color: #fff;
  font-size: 2.4rem;
  height: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 600;
  font-family: "SF Pro Display" !important;
}
.toshop_african {
  color: #fff;
  font-family: "SF Pro Display" !important;
  font-size: 3.8rem;
  font-weight: 800;
}
.toshop_african1 {
  color: #fff;
  font-family: "SF Pro Display" !important;
  font-size: 2.2rem;
  font-weight: lighter;
  margin-top: -1rem;
  word-spacing: 3px;
}

.contentofdiv_stats {
  margin-top: 5rem;
  color: #393939;
  font-weight: normal;
  font-size: 1.4rem;
}
.contentofdiv_stats1 {
  color: #393939;
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: -1.5rem;
}

.onemillionmobile {
  margin-top: 3rem !important;
}

.mobileviewstasttext {
  width: 20rem;
  margin-left: -50% !important;
}
@media (max-width: 450px) {
  .mobileviewofrowininnovative {
    display: flex !important;
    justify-content: center !important;
  }
  .main_div_Innovative {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .toshop_african {
    color: #fff;
    font-family: "SF Pro Display" !important;
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
  }

  .stats_div {
    position: absolute;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: #087781;
    margin-left: 18rem;
    margin-top: 0rem;
    font-family: "SF Pro Display" !important;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 800;
  }

  .onemillionmobile {
    margin-top: 4rem !important;
    font-size: 4.6rem;
  }

  .contentofdiv_stats {
    margin-top: 5rem;
    color: #393939;
    font-weight: normal;
    font-size: 2rem;
    width: 30rem !important;
  }
  .contentofdiv_stats1 {
    color: #393939;
    font-weight: normal;
    font-size: 2rem;
    margin-top: -1.5rem;
    width: 30rem !important;
  }

  .stats_div1 {
    position: absolute;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: #087781;
    margin-top: 24rem;
    margin-left: 18rem;
    font-family: "SF Pro Display" !important;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 800;
  }

  .stats_div2 {
    position: absolute;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: #087781;
    margin-top: 50rem;
    margin-left: 18rem;
    font-family: "SF Pro Display" !important;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 800;
  }

  .stats_div3 {
    position: absolute;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: #087781;
    margin-top: 80rem;
    margin-left: 18rem;
    font-family: "SF Pro Display" !important;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 800;
  }
}

/* 320px */

@media (max-width: 375px) {
  .main_div_Innovative {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .toshop_african {
    color: #fff;
    font-family: "SF Pro Display" !important;
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
  }

  .stats_div {
    position: absolute;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: #087781;
    margin-left: 13rem;
    margin-top: 0rem;
    font-family: "SF Pro Display" !important;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 800;
  }

  .onemillionmobile {
    margin-top: 4rem !important;
    font-size: 4.6rem;
  }

  .contentofdiv_stats {
    margin-top: 5rem;
    color: #393939;
    font-weight: normal;
    font-size: 2rem;
    width: 30rem !important;
  }
  .contentofdiv_stats1 {
    color: #393939;
    font-weight: normal;
    font-size: 2rem;
    margin-top: -1.5rem;
    width: 30rem !important;
  }

  .stats_div1 {
    position: absolute;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: #087781;
    margin-top: 24rem;
    margin-left: 13rem;
    font-family: "SF Pro Display" !important;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 800;
  }

  .stats_div2 {
    position: absolute;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: #087781;
    margin-top: 50rem;
    margin-left: 13rem;
    font-family: "SF Pro Display" !important;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 800;
  }

  .stats_div3 {
    position: absolute;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: #087781;
    margin-top: 80rem;
    margin-left: 13rem;
    font-family: "SF Pro Display" !important;
    color: #fff;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 800;
  }
}
