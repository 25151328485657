.headerr {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.logoc {
  height: 6rem;
}
.logotexttt {
  height: 3rem;
  margin-left: -1.7rem;
  margin-bottom: 0.5rem;
}
.headerclasss {
  width: 80%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.a_links a.nav-link {
  font-family: "SF Pro Display" !important;
  color: #553c02 !important;
  text-decoration: none;
  font-size: 1.7rem !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #553c02 !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
}
