.main_of_features {
  text-align: center;
  margin-top: 10rem;
}

.h1_of_features {
  font-family: "SF Pro Display" !important;
  color: #393939;
  font-size: 3.2rem;
  font-weight: 800;
}

.span_h1_heading {
  color: #94cb64;
}
.mobile_image_features {
  height: 50rem;
}
.iconmadebyghias {
  background-image: linear-gradient(to bottom, #f65904, #ffb300);
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

.secure_icon {
  filter: invert(1);
  height: 2.5rem;
  margin-top: 0.8rem;
}

.float_right_div {
  float: right;
  margin-top: 7rem;
  margin-right: -10rem;
}

.float_right_div22 {
  float: left;
  margin-top: 7rem;
}

@media (max-width: 998px) {
  .float_right_div {
    float: right !important;
    margin-top: 7rem !important;
    margin-right: 0rem !important;
  }
}
.display_flex_div {
  display: flex;
  margin-bottom: 2rem;
}

.heading_div_features {
  color: #087781;
  font-size: 1.8rem;
  font-weight: 800;
  font-family: "SF Pro Display" !important;
  text-align: left;
}

.text_ofFeaturs {
  margin-left: 2rem;
}

.lineHeight_of_features_text {
  line-height: 0.3rem;
  margin-top: 1rem;
}

.text_div_features {
  text-align: left;
  font-family: "SF Pro Display" !important;
  color: #393939;
  font-size: 1.5rem;
}

@media (max-width: 425px) {
  .mobile_image_features {
    height: 40rem;
  }
}
