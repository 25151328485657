.maindivofhowitworks {
  margin-top: 8rem;
}

.h1ofhowitworks {
  font-family: "SF Pro Display" !important;
  font-weight: bold;
  color: #393939;
  font-size: 2.8rem;
  text-align: center;
}

.jutsifycenterdivofcolumns {
  display: flex;
  justify-content: center;
  width: 100%;
}

.backg_download_button {
  background-color: #087781;
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  font-family: "SF Pro Display" !important;
}

.horizontolview {
  margin-top: 5rem;
  display: flex;
  width: 100%;
  justify-content: center;
}

.donwloadLogo {
  height: 3.5rem;
  margin-top: 2.3rem;
  margin-left: 2.3rem;
  filter: invert(1);
}

.textofcoloumnhowitworks {
  color: #393939;
  margin-top: 2rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}

.contentofdiv_stats1212 {
  margin-top: 3rem;
  color: #393939;
  font-weight: normal;
  font-size: 1.4rem;
  font-weight: 500;
}
.contentofdiv_stats211 {
  color: #393939;
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: -1.5rem;
  margin-left: 5rem;
  font-weight: 500;
}

.contentofdiv_stats2c {
  color: #393939;
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: -1.5rem;
  font-weight: 500;
}
.contentofdiv_stats2cee {
  color: #393939;
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: -1.5rem;
  margin-left: -3%;
  font-weight: 500;
}

@media (max-width: 768px) {
  .margintopformobile {
    margin-top: 8rem !important;
  }
}
