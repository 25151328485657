.gm-svpc {
  display: none !important;
}

.gmnoprint {
  display: none !important;
}

.gm-style-cc {
  display: none !important;
}

.search_component {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  margin-top: 6rem;
  display: flex;
  width: 34rem;
  border-radius: 3rem;
  justify-content: space-evenly;
  align-items: center;
  height: 4rem;
}
.upperdiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 450px) {
  .mobileviewofmap {
    margin-top: 80rem !important;
  }
}
