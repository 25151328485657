.contactus {
  text-align: center;
  font-family: "SF Pro Display" !important;
  color: #393939;
  font-size: 3.2rem;
  font-weight: 800;
}

.higherbuttonschatdiv {
  display: flex;
  justify-content: center;
}

.chat_make_button {
  background-color: #087781;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 15rem;
  height: 4rem;

  border-radius: 2rem;
}

.p_tag_contactus {
  color: #fff;
  font-family: "SF Pro Display" !important;
  font-size: 1.8rem;
  margin-bottom: -0.3rem;
}

.email_info {
  text-align: center;
  font-family: "SF Pro Display" !important;
  color: #393939;
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 0.5rem;
  margin-left: 3rem;
}

.higherbuttonschatdiv1 {
  background: url("../../assets/imgs/back1.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 50rem;
  border-radius: 4rem;
}

.padding_left_right {
  padding-left: 20rem;
  padding-right: 20rem;
}

.background_clolr_black {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  border-radius: 4rem;
}

.intro_call_heading {
  color: #fff;
  font-family: "SF Pro Display" !important;
  font-size: 3.4rem;
  font-weight: 800;
}

.conatiner_of_div {
  padding-left: 15rem;
  padding-top: 8rem;
  padding-right: 15rem;
}

.p_tag_contactus1122 {
  color: #fff;
  font-family: "SF Pro Display" !important;
  font-size: 1.4rem;
}

.lineHeight_of_text_data {
  line-height: 0.7rem;
}

.align_in_straight_line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.call_button {
  background-color: #94cb64;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  height: 4rem;
  width: 15rem;
  border-radius: 3rem;
  margin-top: 2rem;
}

.schedule1 {
  color: #fff;
  font-family: "SF Pro Display" !important;
  font-size: 1.7rem;
  font-weight: 800;
  margin-top: 1rem;
}
