.maindiv_of_revloutiun {
  margin-top: 10rem;
  text-align: center;
}
.headiMain_div_of {
  font-family: "SF Pro Display" !important;
  color: #393939;
  font-size: 3.2rem;
  font-weight: 800;
}
.headiMain_div_of1 {
  font-family: "SF Pro Display" !important;
  color: #94cb64;
  font-size: 3.2rem;
  font-weight: 800;
}
.maindiv_of_revloutiun h1 {
  line-height: 2.5rem;
}
.seagren_box_back {
  height: 37rem;
  width: 37rem;
  background-color: #087781;
  border-radius: 50%;
}
.textext {
  color: #fff;
  font-family: "SF Pro Display" !important;
  font-weight: 800;
  font-size: 3.2rem;
}
.seagren_box_back h1 {
  line-height: 2rem;
}

.logostodownloadapp {
  width: 18rem;
}

.own_a_atore {
  background-color: #fff;
  width: 50rem;
  height: 15rem;
  border-radius: 3rem;
  box-shadow: 2px 2px 2px 2px #888888;
}

.doe_po_p {
  font-family: "SF Pro Display" !important;
  color: #393939;
  margin-top: 3rem;
  font-weight: 800;
  font-size: 2.8rem;
}

.bitttton {
  padding-right: 4rem;
  padding-left: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: solid #fff 1px;
  border-radius: 2rem;
  color: #fff;
  background-color: #087781;
  font-weight: 800;
  font-family: "SF Pro Display" !important;
}

.mobileveiwsofimage {
  height: 37rem;
}
