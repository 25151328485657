.hasslesection {
  height: 60rem;
  background-color: #087781;
  width: 60rem;
  border-radius: 50%;
  margin-top: 2rem;
  font-family: "SF Pro Display" !important;
}

.maindiv {
  display: flex;
  justify-content: center;
}

.headingggggg {
  color: #fff;
  font-size: 4rem;
  line-height: 2rem;
}

.hasle_div_text {
  text-align: left;
  margin-top: 12rem;
  /* height: 100%; */
  margin-left: 24%;
  /* width: 50%; */
}
.boy_pic {
  position: absolute;
  height: 50rem;
  margin-left: -15rem;
}
.hasle_div_bottom_text {
  text-align: left;
  margin-top: 8rem;
  margin-left: 50%;
}
.bottom_text_with_button {
  color: #fff;
  line-height: 1rem;
  font-size: 1.8rem;
  font-weight: 100;
}
.download_button {
  font-family: "SF Pro Display" !important;
  color: #fff;
  background-color: #f65904;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  border-radius: 3rem;
  border: solid rgb(8, 119, 129) 1px;
}

.bottom_text_with_button1 {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 100;
}

.bottom_text_with_button2 {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 800;
}
.brwoncircle {
  height: 23rem;
  width: 23rem;
  border-radius: 50%;
  background-color: #553c02;
  position: absolute;
  margin-top: -7rem;
  margin-left: -11rem;
}
.greencircle {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  background-color: #94cb64;
  position: absolute;
  margin-top: -2rem;
  margin-left: 11rem;
}

.seagreencircle {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  background-color: #087781;
  position: absolute;
  margin-top: -10rem;
  margin-left: -30rem;
}

.background_of_icons {
  position: absolute;
  margin-left: 28rem;
  margin-top: 10rem;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 7px 7px 6px #888888;
}
.iconss {
  position: absolute;
  margin-left: 29.5rem;
  margin-top: 11.5rem;
  font-size: 2rem;
  color: #f65904 !important;
}

.gobil1 {
  position: absolute;
  height: 13rem;
  margin-left: 50rem;
  margin-top: 11rem;
}

.gobil2 {
  position: absolute;
  height: 8rem;
  margin-left: 54rem;
  margin-top: 37rem;
}

.gobil3 {
  position: absolute;
  height: 7rem;
  margin-left: -20rem;
  margin-top: 45rem;
}

.gobil4 {
  position: absolute;
  height: 8rem;
  margin-left: -22rem;
  margin-top: 11rem;
}

.gobil5 {
  position: absolute;
  height: 29rem;
  margin-left: -25rem;
  margin-top: 4rem;
  z-index: -1;
  width: 100rem;
}

.exchangedownload {
  display: flex;
  justify-content: center !important;
  margin-left: -10rem;
}

@media (max-width: 450px) {
  .exchangedownload {
    display: flex;
    justify-content: center !important;
    margin-left: 0rem;
  }
  .gobil5 {
    display: none;
  }
  .boy_pic {
    position: absolute;
    height: 35rem;
    margin-left: 16rem;
    margin-top: -18rem;
  }
  .brwoncircle {
    display: none;
  }
  .greencircle {
    display: none;
  }
  .hasle_div_bottom_text {
    text-align: center;
    margin-top: 5rem;
    margin-left: 0;
  }
  .hasle_div_text {
    text-align: center;
    margin-top: 19rem;
    /* height: 100%; */
    margin-left: 0;
    /* width: 50%; */
  }
  .background_of_icons {
    position: absolute;
    margin-left: 28rem;
    margin-top: 5rem;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 7px 7px 6px #888888;
  }
  .iconss {
    position: absolute;
    margin-left: 29.5rem;
    margin-top: 6.5rem;
    font-size: 2rem;
    color: #f65904 !important;
  }
  .hasslesection {
    height: 60rem;
    background-color: #087781;
    width: 60rem;
    border-radius: 50%;
    margin-top: 18rem;
    font-family: "SF Pro Display" !important;
  }
  .gobil1 {
    position: absolute;
    height: 8rem;
    margin-left: 40rem;
    margin-top: -12rem;
  }

  .gobil4 {
    position: absolute;
    height: 5rem;
    margin-left: 10rem;
    margin-top: -15rem;
  }

  .gobil2 {
    position: absolute;
    height: 8rem;
    margin-left: 44rem;
    margin-top: 48rem;
  }
}

@media (max-width: 375px) {
  .hasslesection {
    height: 55rem;
    background-color: #087781;
    width: 55rem;
    border-radius: 50%;
    margin-top: 20rem;
    font-family: "SF Pro Display" !important;
  }

  .gobil2 {
    display: none !important;
  }

  .background_of_icons {
    position: absolute;
    margin-left: 24rem;
    margin-top: 1rem;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 7px 7px 6px #888888;
  }
  .iconss {
    position: absolute;
    margin-left: 25.5rem;
    margin-top: 2.5rem;
    font-size: 2rem;
    color: #f65904 !important;
  }
}

@media (max-width: 320px) {
  .hasslesection {
    height: 50rem;
    background-color: #087781;
    width: 50rem;
    border-radius: 50%;
    margin-top: 20rem;
    font-family: "SF Pro Display" !important;
  }
  .hasle_div_text {
    text-align: left;
    margin-top: 15rem;
    /* height: 100%; */
    margin-left: 22%;
    /* width: 50%; */
  }
  .boy_pic {
    position: absolute;
    height: 30rem;
    margin-left: 11rem;
    margin-top: -18rem;
  }

  .gobil4 {
    position: absolute;
    height: 5rem;
    margin-left: 7rem;
    margin-top: -15rem;
  }
  .gobil1 {
    position: absolute;
    height: 8rem;
    margin-left: 32rem;
    margin-top: -12rem;
  }
  .gobil2 {
    display: none !important;
  }

  .background_of_icons {
    position: absolute;
    margin-left: 20rem;
    margin-top: 1rem;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 7px 7px 6px #888888;
  }
  .iconss {
    position: absolute;
    margin-left: 21.5rem;
    margin-top: 2.5rem;
    font-size: 2rem;
    color: #f65904 !important;
  }
}
