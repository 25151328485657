.fotterr_1 {
  margin-top: 10rem;
  background-color: #efefef;
  height: 45rem;
  display: flex;
  justify-content: center;
  width: 100% !important;
}

.logoc {
  height: 6rem;
}
.logotexttt {
  height: 3rem;
  margin-left: -1.7rem;
  margin-bottom: 0.5rem;
}

.logooffooter {
  margin-top: 7rem;
}

.text_under_logo {
  line-height: 1rem;
  margin-top: 4rem;
  text-align: center;
  font-family: "SF Pro Display" !important;
  font-size: 1.6rem;
  color: #393939;
  margin-left: -2rem;
}

.iconsClass {
  justify-content: space-evenly;
  display: flex;
  width: 20rem;
  margin-left: 3rem;
  margin-top: 4rem;
}

.pageLinksatags {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;
}

.pageLinksatags a {
  font-family: "SF Pro Display" !important;
  color: #553c02 !important;
  text-decoration: none;
  font-weight: 800;
}

.horizontalline {
  width: 90vw;
  position: absolute;
  margin-left: -35%;
  margin-top: 4rem;
  color: #707070;
}

.bottommenuicons {
  margin-top: 8rem;
  display: flex;
  position: absolute;
  width: 90vw;
  margin-left: -35%;
}

.mainButton {
  font-family: "SF Pro Display" !important;
  font-weight: bold;
  color: #393939;
}

.bottom_a_links {
  width: 50vw;
  display: flex;
  justify-content: space-evenly;
}

.bottom_a_links a {
  color: #707070;
  font-weight: 500;
}

.copyright {
  color: #707070;
  font-weight: 500;
  margin-left: 17rem;
}
.mobileview {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
  margin-left: -2rem;
}
@media (max-width: 450px) {
  .pageLinksatags {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
  .mobileview {
    display: flex;
    flex-direction: column;
    width: 40%;
    text-align: center;
  }

  .fotterr_1 {
    margin-top: 10rem;
    background-color: #efefef;
    height: 70rem;
    display: flex;
    justify-content: center;
    width: 100% !important;
  }

  .horizontalline {
    width: 90vw;
    position: absolute;
    margin-left: -20%;
    margin-top: 4rem;
    color: #707070;
  }

  .bottommenuicons {
    margin-top: 8rem;
    display: flex;
    position: absolute;
    width: 90vw;
    margin-left: -20%;
    flex-direction: column;
  }
  .mainButton {
    font-family: "SF Pro Display" !important;
    font-weight: bold;
    color: #393939;
    text-align: center;
    font-size: 2.4rem;
  }

  .bottom_a_links {
    width: 90vw;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
  }
  .copyright {
    color: #707070;
    font-weight: 500;
    text-align: center;
    margin-left: 0rem;
  }
}

@media (max-width: 375px) {
  .bottommenuicons {
    margin-top: 8rem;
    display: flex;
    position: absolute;
    width: 90vw;
    margin-left: -15%;
    flex-direction: column;
  }
}
